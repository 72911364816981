import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap'
import NavBar from '../NavBar'
import '../../App.scss'


class PrivacyPolicy extends Component {

    render() {
        return (
            <div className="SignUp">
                <NavBar className="navbar--default" />
                <main role="main" className="content">
                <div>
                <h1 className="text-center my-5">Privacy Policy</h1>
                <Container fluid={true}>
                <Row>
                <Col xs={12} sm={8} md={5} lg={4} xl={9} className="col--center">
                <div class ='innerText'>This privacy policy has been compiled to better serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used online.PII, as described in US privacy law and information security,
                is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context.Please read our privacy policy carefully to get a clear understanding of how we collect,
                use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
                <br/>
                </div>
                <span id='infoCo'></span>
                <br/>
                <div class ='grayText'>
                <strong>
                What personal information do we collect from the people that visit our blog, website or app?
                </strong>
                </div>
                <br />
                <div class ='innerText'>
                When ordering or registering on our site, as appropriate, you may be asked to enter your email address, credit card information  or other details to help you with your experience.
                </div>
                <br/>
                <div class ='grayText'>
                <strong>When do we collect information?</strong>
                </div>
                <br />
                <div class ='innerText'>
                We collect information from you when you register on our site, place an order, subscribe to a newsletter, fill out a form, Open a Support Ticket or enter information on our site.
                </div><br/>
                <span id='infoUs'></span>
                <br/>
                <div class ='grayText'>
                <strong>How do we use your information?</strong>
                </div><br />
                <div class ='innerText'>
                We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways: <br/><br/>
                </div>
                <div class ='innerText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <strong>&bull; </strong>
                To improve our website in order to better serve you.</div>
                <div class ='innerText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <strong>&bull; </strong>
                To allow us to better service you in responding to your customer service requests.
                </div><div class ='innerText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <strong>&bull; </strong>
                To quickly process your transactions.</div>
                <div class ='innerText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <strong>&bull; </strong>
                To send periodic emails regarding your order or other products and services.
                </div>
                <span id='infoPro'></span><br/>
                <div class ='grayText'>
                <strong>How do we protect your information?</strong>
                </div>
                <br />
                <div class ='innerText'>
                Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible.<br/><br/>
                </div>
                <div class ='innerText'>
                Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential.In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.
                </div>
                <br/>
                <div class ='innerText'>
                We implement a variety of security measures when a user places an order enters, submits, or accesses their information to maintain the safety of your personal information.
                </div><br/>
                <div class ='innerText'>All transactions are processed through a gateway provider and are not stored or processed on our servers.</div>
                <span id='coUs'></span><br/>
                <div class ='grayText'>
                <strong>Do we use 'cookies'?</strong>
                </div><br />
                <div class ='innerText'>
                Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.
                </div>
                <div class ='innerText'><br/>
                <strong>We use cookies to: </strong>
                </div>
                <div class ='innerText'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <strong>&bull; </strong>
                Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future.We may also use trusted third-party services that track this information on our behalf.
                </div>
                <div class ='innerText'><br/>
                You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies.You do this through your browser settings.Since browser is a little different, look at your browser's Help Menu to learn the correct way to modify your cookies.<br/>
                </div><br/>
                <div class ='innerText'>
                If you turn cookies off, Some of the features that make your site experience more efficient may not function properly.It won't affect the user's experience that make your site experience more efficient and may not function properly.
                </div><br/><span id='trDi'></span><br/>
                <div class ='grayText'>
                <strong>Third-party disclosure</strong>
                </div>
                <br />
                <div class ='innerText'>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice.This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property or safety.
                <br/><br/>
                However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
                </div>
                <span id='trLi'></span><br/>
                </Col>
                </Row>
                </Container>
                <Container fluid={true} className="footer--absolute py-5">
                    <Row className="mb-5">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-align-center text-center">
                        <a className="text-center text-white mx-2" href="/privacy-policy">Privacy Policy</a>
                        <a className="text-center text-white mx-2" href="/terms-of-use">Terms of Use</a>
                    </Col>
                    </Row>
                </Container>
            </div>
            </main >
          </div >
        )
    }
}

export default PrivacyPolicy;