import React, { Component } from 'react';
import './NavBar.scss';
import { Nav, Navbar, Button, Modal, Form, Card } from 'react-bootstrap'
import { User, Home, UserCheck, AtSign, Lock, LogIn, Settings } from 'react-feather'
import { withRouter } from 'react-router-dom'
import auth from '../../auth'

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSignUp: false,
            showManageSites: false,
            showLogIn: false,
            logInError: false,
            showResetLinkSent: false,
            showPasswordRecovery: false,
            sessionAuthenticated: this.props.sessionAuthenticated
        }
        this.handleSignUp = this.handleSignUp.bind(this)

    }

    handlePasswordRecovery = () => {
        const recoveryEmail = document.querySelector('.recoveryInput--email').value;
        const formData = {
            email: recoveryEmail
        }
        fetch(`${process.env.REACT_APP_SITE_URL}/sendPasswordResetEmailFromLandingPage`, {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(res => {
            this.setState({
                showResetLinkSent: true
            })
        })

    }
    showPasswordRecovery = () => {
        this.setState({ 
            showPasswordRecovery: true,
            showResetLinkSent: false,
            showLogIn: false
        })
    }
    hidePasswordRecovery = () => {
        this.setState({ showPasswordRecovery: false })
    }
    showSignUp = () => {
        this.setState({ showSignUp: true })
    }
    hideSignUp = () => {
        this.setState({ showSignUp: false })
    }
    showLogIn = () => {
        this.setState({ showLogIn: true })
    }
    hideLogIn = () => {
        this.setState({ showLogIn: false })
    }
    handleLogIn = () => {
        // this.setState({ logInError: true })
        const email = document.querySelector('.logInInput--email').value
        const password = document.querySelector('.logInInput--password').value
        const logInData = {
            email: email,
            password: password
        }
        // Set user cookie and session data
        auth.login(email, password, () => {
            this.props.history.push('/app')
        })
    }

    handleSignUp = () => {
        let email = document.querySelector('.signUpInput--email').value
        let password = document.querySelector('.signUpInput--password').value
        const regex = /\S+@\S+\.\S+/
    
        if (password.length < 6) {
          this.setState((prevState) => {
              return {
                  showPasswordRequirements: true
              }
          })
        } else {
          this.setState((prevState) => {
            return {
                showPasswordRequirements: false
            }
        })
        }
    
        if (!regex.test(email)) {
          this.setState((prevState) => {
            return {
              showEmailRequirements: true
            }
          })
        } else {
          this.setState((prevState, props) => {
            return { showEmailRequirements: false }
          })
        }
    
        let formData = {
          email: email,
          password: password
        }
    
        if(password.length > 5 && regex.test(email)) {
          fetch(`${process.env.REACT_APP_SITE_URL}/signUp`, {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(formData)
          })
          .then(res => res.json())
          .then(res => {
            if(res === false) {
              this.setState((prevState, props) => {
                return { showUserIsRegistered: true }
              })
            } else {
              this.setState((prevState, props) => {
                return { showUserIsRegistered: false }
              })
            }
            if(res !== false
              && this.state.showEmailRequirements === false
              && this.state.showPasswordRequirements === false
              && this.state.showUserIsRegistered === false) {
                // Set user cookie and session data
                auth.login(email, password, () => {
                  this.props.history.push('/app')
                })
            }
          })
        }
      }
    componentWillMount = () => {
        // auth.isAuthenticated().then((authResponse) => {
        //     if(authResponse === true) {
        //       this.setState({ sessionAuthenticated: true })
        //     } else {
        //       this.setState({ sessionAuthenticated: false })
        //       //this.props.history.push('/')
        //     }
        // })
    }

    showManageSites = () => {
        this.setState({ showManageSites: true })
    }

    handleHomeClick = () => {
        
        this.props.history.push('/app')
    }

    render (){
        return (
            <div className={`${this.props.className} NavBar`}>
                <Modal show={this.state.showSignUp} onHide={this.hideSignUp} size="md">
                    <Modal.Header closeButton>
                        <Modal.Title>Sign Up</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label className="mb-1 addSite--label">Email</Form.Label>
                            <Form.Control className="signUpInput--email" type="text" size="md" placeholder="Email:" />
                            {
                                this.state.showEmailRequirements &&
                                    <small className="heroSignUp--text-danger">Please enter a valid email.</small>
                            }
                            {
                            this.state.showUserIsRegistered &&
                                <small className="heroSignUp--text-danger">A user with that email address is already registered.</small>
                            }
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="mb-1 addSite--label">Password</Form.Label>
                            <Form.Control className="signUpInput--password" type="password" size="md" placeholder="Password:" />
                            {
                            this.state.showPasswordRequirements &&
                                <small className="heroSignUp--text-danger">Password must be at least 6 characters long.</small>
                            }
                        </Form.Group>
                        <Button className="signInSubmit col-xs-12 col-sm-5 col-md-4" type="button" onClick={() => this.handleSignUp()}>
                            Sign Up
                        </Button>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showPasswordRecovery} onHide={this.hidePasswordRecovery} size="md">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Lock className="icon--navButton" size={22}/> Reset Password
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.showResetLinkSent ?
                            <React.Fragment>
                                <p className="mt-3">A link to reset your password was sent to the email provided.</p>
                            </React.Fragment>
                            : <React.Fragment>
                                <Form.Group>
                                    <Form.Label className="mb-0 addSite--label">Email</Form.Label>
                                    <small>Enter the email address associated with your account.</small>
                                    <Form.Control className="recoveryInput--email" type="text" size="md" placeholder="Email:" />
                                </Form.Group>
                                <Button className="float-left signInSubmit" type="button" onClick={() => this.handlePasswordRecovery()}>
                                    Send Password Reset Link
                                </Button>
                            </React.Fragment>
                        }
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showLogIn} onHide={this.hideLogIn} size="md">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Log In
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form.Group>
                        <Form.Label className="mb-1 addSite--label">Email</Form.Label>
                        <Form.Control className="logInInput--email" onKeyDown={this.addBadSite} type="text" size="md" placeholder="Email:" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="mb-1 addSite--label">Password</Form.Label>
                        <Form.Control className="logInInput--password" onKeyDown={this.addBadSite} type="password" size="md" placeholder="Password:" />
                        { this.state.logInError && <small className="text-danger">Incorrect username or password.</small> }
                    </Form.Group>
                        <Button className="signInSubmit btn-info col-xs-12 col-sm-5 col-md-4" type="button" onClick={() => this.handleLogIn()}>
                            Log In
                        </Button><br />
                        <button onClick={() => this.showPasswordRecovery()} className="pl-0 btn btn-link">Forgot password?</button>
                    </Modal.Body>
                </Modal>
                <Navbar className="nav--default" collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Navbar.Brand href="#home" className="brand--thicc nav--brand">dayhub.</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            {/* <Nav.Link href="/" className="menuItem--noHover">Reminders</Nav.Link> */}
                        </Nav>
                        <Nav>
                            {
                                this.state.sessionAuthenticated ?
                                    <React.Fragment>
                                        <Button variant={'warning'} className="btn-warning my-1 mx-1" onClick={() => this.handleHomeClick() }>
                                            <Home className="icon--navButton icon--white" size={22}/>
                                        </Button>
                                        <Button variant={'info'} className="btn-info my-1 mx-1" onClick={() => this.props.history.push('/profile')}>
                                            <User className="icon--navButton" size={22}/>
                                        </Button>
                                    </React.Fragment>
                                    : <React.Fragment>
                                        <Button className="btn btn-info pl-5 pr-5 mx-1 my-1" onClick={() => this.showLogIn()}>Log In</Button>
                                        <Button className="btn btn-primary pl-5 pr-5 mx-1 my-1" onClick={() => this.showSignUp()}>Sign Up</Button>
                                    </React.Fragment>

                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        )
    }
}

export default withRouter(NavBar)