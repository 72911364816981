import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './index.scss';
import App from './App';
import Cookies from 'universal-cookie'
import Profile from './components/Profile'
import ResetPassword from './components/ResetPassword'
import PrivacyPolicy from './components/PrivacyPolicy'
import TermsOfUse from './components/TermsOfUse'
import LandingPage from './LandingPage';
import * as serviceWorker from './serviceWorker';
import ProtectedRoute from './ProtectedRoute'

const cookies = new Cookies()
const userData = cookies.get('userData')
const DefaultTheme = React.lazy(() => import('./components/Theme/defaultTheme'))
const DarkTheme = React.lazy(() => import('./components/Theme/darkTheme'))

//STEP 2:
//create a parent component that will load the components conditionally using React.Suspense
const ThemeSelector = ({ children }) => {
  // Store in cookie
  const CHOSEN_THEME = userData && userData.theme || 'default';
  return (
    <>
      <React.Suspense fallback={<></>}>
        {(CHOSEN_THEME === 'default') && <DefaultTheme />}
        {(CHOSEN_THEME === 'dark') && <DarkTheme />}
      </React.Suspense>
      {children}
    </>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeSelector>
    <Router>
      <Switch>
        <Route path="/app" exact component={App}/>
        <Route path="/privacy-policy" exact component={PrivacyPolicy}/>
        <Route path="/terms-of-use" exact component={TermsOfUse}/>
        <Route path="/profile" exact component={Profile}/>
        <Route path="/reset-password" exact component={ResetPassword}/>
        <Route path="/" exact render={() => {
          const urlParams = new URLSearchParams(window.location.search);
          const action = urlParams.get('action');
          return action === 'signUp' ? <LandingPage signUp/> : <LandingPage/>
        }}/>
      </Switch>
    </Router>
    </ThemeSelector>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
