import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "./auth";

export const ProtectedRoute = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        auth.isAuthenticated().then((authResponse) => {
          if(authResponse === true) {
            return (<Component {...props} />);
          } else {
            window.location.href ='/'
            props.history.push('/')
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: {
                    from: props.location
                  }
                }}
              />
            );
          }
        })
      }}
    />
  );
};

export default ProtectedRoute
