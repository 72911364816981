import React, { Component } from 'react';
import './Profile.scss';
import '../../App.scss'
import classnames from 'classnames'
import { NavBar, ToggleSwitch } from '../../components/'
import snakeCase from 'lodash/snakeCase';
import { Nav, Navbar, Button, Modal, Form, Card, Tabs, Tab, InputGroup, Alert, FormControl, FormLabel} from 'react-bootstrap'
import { User, UserCheck, AtSign, Lock, LogIn, Settings, Mail, Clock } from 'react-feather'
import { withRouter } from 'react-router-dom'
import auth from '../../auth'
import  mapThemeToClasses from '../../utils/themes'
import Cookies from 'universal-cookie'


const cookies = new Cookies()
const userData = cookies.get('userData')
const ACTIVITIES = [
    'News Feeds', 'Likes and Comments', 'Live Stream'
]

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = { 
          firstName: null,
          lastName: null,
          email: null,
          sessionAuthenticated: null,
          showAlert: null,
          showPasswordResetAlert: null,
          alertType: null,
          alertText: null,
          blockMessage: null,
          timeInterval: null,
          themePreference: userData && userData.theme,
          only: ACTIVITIES.map(snakeCase)
         }
         this.getNavBar = this.getNavBar.bind(this)
        //  this.addReminder = this.addReminder.bind(this)
    
         this.componentWillMount = () => {
            userData && mapThemeToClasses(userData.theme)
          auth.isAuthenticated().then((authResponse) => {
            if(authResponse === true) {
              this.setState({ sessionAuthenticated: true })
            } else {
              this.setState({ sessionAuthenticated: false })
              this.props.history.push('/')
            }
          })
          this.getUserData()
        }

        this.componentDidUpdate = () => {
            this.getNavBar()
            //this.mapThemeToClasses()
            mapThemeToClasses(this.state.themePreference)
        }

        this.componentDidMount = () => {
            // this.getToggleSwitch()
        }
    }

    getToggleSwitch = () => {
        return (<ToggleSwitch theme="default"
        className="d-flex ml-1"
        enabled={this.state.darkThemeEnabled}
        onStateChanged={this.toggleNotifications}
        />)
    }

    toggleNotifications = ({ darkThemeEnabled }) => {
        const { only } = this.state;
        if (this.state.darkThemeEnabled) {
            // Send updated theme preference to server
            this.setState((prevState, props) => {
                cookies.set('userData', {
                    email: this.state.email,
                    theme: 'default',
                    blockMessage: this.state.blockMessage,
                    timeInterval: this.state.timeInterval
                })
                return { darkThemeEnabled: false, themePreference: 'default' }
            }, () => {
                this.updateThemePreference()
                mapThemeToClasses(this.state.themePreference)
            })
        } else {
            // Send updated theme preference to server
            // Might want to remove this or remove updating the state in the response from update theme preference
            this.setState((prevState, props) => {
                return { darkThemeEnabled: true, themePreference: 'dark' }
            }, () => {
                this.updateThemePreference()
                mapThemeToClasses()
            })
        }
    }

    sendPasswordResetLink = () => {
        const userData = cookies.get('userData')
        const sessionData = cookies.get('sessionData')
        let formData = {
            email: userData.email,
            clientAuthToken: sessionData.authToken
        }

        fetch(`${process.env.REACT_APP_SITE_URL}/sendPasswordResetEmail`, {
          method: "POST",
          headers: {
              'Content-type': 'application/json'
          },
          body: JSON.stringify(formData)
        })
        .then(() => {
            this.showAlert('info', 'A link to reset your password has been sent to your email address.')
        })
    }

    showAlert = (alertType, alertText) => {
        this.setState({ 
            showAlert: true,
            alertType: alertType,
            alertText: alertText
        })
    }
    handleEmail = (e) => {
        console.log(e.target.value)
        this.setState({
            email: e.target.value
        });
    }

    handleBlockMessage = (e) => {
        this.setState({
            blockMessage: e.target.value
        })
    }

    handleTimeInterval = (e) => {
        this.setState({
            timeInterval: e.target.value
        })
    }

    handleFirstName = (e) => {
        console.log(e.target.value)
        this.setState({
            firstName: e.target.value
        });
    }

    handleLastName = (e) => {
        console.log(e.target.value)
        this.setState({
            lastName: e.target.value
        });
    }

    hideAlert = () => {
        this.setState({ showAlert: false })
    }

    getUserData = () => {
        //function to get the user data in here
        const userData = cookies.get('userData')
        const sessionData = cookies.get('sessionData')
        let formData = {
            email: userData.email,
            clientAuthToken: sessionData.authToken
        }

        fetch(`${process.env.REACT_APP_SITE_URL}/getUserProfileData`, {
          method: "POST",
          headers: {
              'Content-type': 'application/json'
          },
          body: JSON.stringify(formData)
        })
        .then(res => res.json())
        .then((res) => {
            this.setState({ 
                email: res.email,
                firstName: res.firstName,
                lastName: res.lastName,
                themePreference: userData.theme,
                darkThemeEnabled: userData.theme === 'dark' ? true : false,
                blockMessage: res.blockMessage,
                timeInterval: res.timeInterval
            })
        })
    }



    updateThemePreference = () => {
        const userData = cookies.get('userData')
        const sessionData = cookies.get('sessionData')
        let formData = {
            themePreference: this.state.themePreference,
            blockMessage: this.state.blockMessage,
            timeInterval: this.state.timeInterval,
            email: userData.email,
            clientAuthToken: sessionData.authToken
        }
        fetch(`${process.env.REACT_APP_SITE_URL}/updatePreferences`, {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(res => res.json())
        .then((res) => {})
        .then(() => {
            cookies.set('userData', {
                email: this.state.email, 
                theme: this.state.themePreference
            })
        })
        .then(() => {
            window.location.reload()
        })
    }

    saveAccountDetails = () => {
        //function to get the user data in here
        const sessionData = cookies.get('sessionData')
        let formData = {
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            clientAuthToken: sessionData.authToken
        }
        fetch(`${process.env.REACT_APP_SITE_URL}/updateProfile`, {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(res => res.json())
        .then((res) => {
            this.setState({ 
                email: res.email,
                firstName: res.firstName,
                lastName: res.lastName
            })
        })
        .then(() => this.showAlert('success', 'Success! Profile updated successfully.'))
    }

    savePreferences = () => {
        const userData = cookies.get('userData')
        const sessionData = cookies.get('sessionData')
        let formData = {
            timeInterval: this.state.timeInterval,
            blockMessage: this.state.blockMessage,
            themePreference: this.state.themePreference,
            email: userData.email,
            clientAuthToken: sessionData.authToken
        }
        fetch(`${process.env.REACT_APP_SITE_URL}/updatePreferences`, {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(res => res.json())
        .then((res) => {
            this.setState({ 
                timeInterval: res.timeInterval,
                blockMessage: res.blockMessage
            })
        })
        .then(() => this.showAlert('success', 'Success! Preferences updated successfully.'))
    }

    getNavBar = () => {
        return (<NavBar sessionAuthenticated className={this.state.themePreference === 'dark' ? 'navbar--dark' : 'navbar--default'}/>)
    }
    render (){
        const { darkThemeEnabled } = this.state.themePreference === 'dark' ? true : false;
        const headingClasses = classnames(
            'font-weight-light h2 mb-0 pl-4',
            darkThemeEnabled ? 'text-dark' : 'text-secondary'
        );
        return (
            <React.Fragment>
                {this.getNavBar()}
                <div className="secondaryNav">
                    <h1 className="mt-5">Profile</h1>
                </div>
                <main role="main" className="container">
                    <div className="starter-template mt-4">
                    <div className="notificationContainer">
                        { this.state.showAlert &&
                            <Alert variant={this.state.alertType} className="mb-4" onClose={this.hideAlert} dismissible>
                            <p className="mx-0 px-0 mb-0">{this.state.alertText}</p>
                            </Alert>
                        }
                    </div>
                    <Tabs defaultActiveKey="account" id="uncontrolled-tab-example">
                        <Tab eventKey="account" title="Account">
                        <div className="row mt-4">
                            <h3>Account Details</h3>
                        </div>
                        <div className="row">
                        <label htmlFor="basic-url">First Name</label>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1"><User size={18}></User></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    placeholder="Jane"
                                    aria-label="Jane"
                                    aria-describedby="basic-addon1"
                                    value={this.state.firstName !== 'firstName' ? this.state.firstName : ''}
                                    onChange={(e) => this.handleFirstName(e)}
                                />
                            </InputGroup>
                        </div>
                        <div className="row">
                        <label htmlFor="basic-url">Last Name</label>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1"><User size={18}></User></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    placeholder="Doe"
                                    aria-label="Doe"
                                    aria-describedby="basic-addon1"
                                    value={this.state.lastName !== 'lastName' ? this.state.lastName : ''}
                                    onChange={(e) => this.handleLastName(e)}
                                ></Form.Control>
                            </InputGroup>
                        </div>
                        <div className="row">
                        <label htmlFor="basic-url">Email</label>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1"><Mail size={18}></Mail></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    placeholder="you@example.com"
                                    aria-label="you@example.com"
                                    aria-describedby="basic-addon1"
                                    value={this.state.email}
                                    onChange={(e) => this.handleEmail(e)}
                                />
                            </InputGroup>
                        </div>
                        <div className="row">
                            <Button onClick={() => this.saveAccountDetails()}>Save Account Details</Button>
                        </div>
                        <div className="row mt-4">
                            <h3>Update Password</h3>
                        </div>
                        <div className="row">
                            <label>Send a link to your email to reset your password.</label>
                        </div>
                        <div className="row mb-5">
                            <Button onClick={() => this.sendPasswordResetLink()}>Send Password Reset Link</Button>
                        </div>
                        <div className="row mb-5"></div>
                        <div className="row mb-5"></div>
                        </Tab>
                        <Tab eventKey="profile" title="Preferences">
                            <div className="row mt-4">
                                <h3>Dark Theme</h3>
                            </div>
                            <div className="row mt-2">
                            <ToggleSwitch theme="default"
                                className="d-flex ml-1"
                                enabled={this.state.darkThemeEnabled}
                                onStateChanged={this.toggleNotifications}
                                switchIsOn={this.state.darkThemeEnabled}
                                />
                                <label className="ml-3">Enable dark theme</label>
                            </div>
                            <div className="row mt-4">
                                <h3>Blocked Site Message</h3>
                            </div>
                            <div className="row mt-2">
                                <div className="col col-sm-9 pl-0 pr-0">
                                    <Form.Control
                                        className="mb-1"
                                        placeholder="You're suppoesd to be working right now."
                                        aria-label="You're supposed to be working right now."
                                        value={this.state.blockMessage || "You're supposed to be working right now."}
                                        onChange={(e) => this.handleBlockMessage(e)}
                                    />
                                    <small className="text-muted">This message is shown when trying to access a blocked site during working hours.</small>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <h3>Browsing Time for Blocked Sites</h3>
                            </div>
                            <div className="row mt-2">
                                <div className="col col-sm-9 col-md-3 col-lg-2 pl-0 pr-0 mb-1">
                                    <InputGroup className="mb-1">
                                        <Form.Control
                                            type="number"
                                            placeholder="15"
                                            aria-label="15"
                                            aria-describedby="basic-addon2"
                                            value={this.state.timeInterval}
                                            onChange={(e) => this.handleTimeInterval(e)}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text id="basic-addon2">Minutes</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </div>
                            </div>
                            <div className="row">
                                <small className="text-muted">The amount of time allowed for browsing when access to a blocked site is overriden during working hours.</small>
                            </div>
                            <div className="row">
                                <Button onClick={() => this.savePreferences()}>Save Preferences</Button>
                            </div>
                        </Tab>
                        <Tab eventKey="contact" title="Integrations">
                            <div className="row mt-4">
                                <h3>Coming soon! 👀</h3>
                            </div>
                        </Tab>
                    </Tabs>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}

export default withRouter(Profile)