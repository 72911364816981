import React, { Component } from 'react';
import logo from './logo.svg';
// import image from '../src/assets/landing-page/stay-focused.jpg'
import './App.scss';
import { NavBar } from './components'
import { Container, Button, Row, Col, FormGroup, Image } from 'react-bootstrap'
import auth from './auth'
import { Link, withRouter } from 'react-router-dom'
import editSchedules from '../src/assets/landing-page/editSchedules.gif'
import blockSites from '../src/assets/landing-page/blockSites.gif'
import crushTasks from '../src/assets/landing-page/crushTasks.gif'



class LandingPage extends Component {
  constructor(props) {
    super(props);
    const { history } = this.props
    this.state = {
      showSetReminders: false,
      showUserIsRegistered: false,
      showPasswordRequirements: false,
      showEmailRequirements: false,
      sessionAuthenticated: false
     }
     this.handleSignUp = this.handleSignUp.bind(this)
  }

  showSetReminders = () => {
    this.setState({ showSetReminders: true })
  }

  goToValueProps = () => {
    document.querySelector('.valueProp').scrollIntoView()
  }

  focusSignUp = () => {
    window.scrollTo(0, 0);
    document.querySelector('.signUp--email').focus()
  }

  hideSetReminders = () => {
    this.setState({ showSetReminders: false })
  }

  handleSignUp = () => {
    let email = document.querySelector('.signUp--email').value
    let password = document.querySelector('.signUp--password').value
    const regex = /\S+@\S+\.\S+/

    if (password.length < 6) {
      this.setState((prevState) => {
          return {
              showPasswordRequirements: true
          }
      })
    } else {
      this.setState((prevState) => {
        return {
            showPasswordRequirements: false
        }
    })
    }

    if (!regex.test(email)) {
      this.setState((prevState) => {
        return {
          showEmailRequirements: true
        }
      })
    } else {
      this.setState((prevState, props) => {
        return { showEmailRequirements: false }
      })
    }

    let formData = {
      email: email,
      password: password
    }

    if(password.length > 5 && regex.test(email)) {
      fetch(`${process.env.REACT_APP_SITE_URL}/signUp`, {
        method: "POST",
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      .then(res => res.json())
      .then(res => {
        if(res === false) {
          this.setState((prevState, props) => {
            return { showUserIsRegistered: true }
          })
        } else {
          this.setState((prevState, props) => {
            return { showUserIsRegistered: false }
          })
        }
        if(res !== false
          && this.state.showEmailRequirements === false
          && this.state.showPasswordRequirements === false
          && this.state.showUserIsRegistered === false) {
            // Set user cookie and session data // 
            auth.login(email, password, () => {
              this.props.history.push('/app')
            })
        }
      })
    }
  }


  

  

  render (){
    return (
    <div className="SignUp">
    <NavBar />
    <main role="main" className="content">
      <div className="heroImage">
        <h1 className="text-center text-white">The productivity tool you didn't know you needed.</h1>
        <h4 className="text-center text-white">Block distracting websites to get more of your tasks done faster.</h4>
        <Container fluid={true} className="my-5">
          <Row>
            <Col xs={12} sm={8} md={5} lg={4} xl={3} className="col--center">
              <FormGroup>
                <input type="email" placeholder="Email:" className="form-control mt-3 signUp--email"/>
                {
                  this.state.showEmailRequirements &&
                    <small className="heroSignUp--text-danger">Please enter a valid email.</small>
                }
                {
                  this.state.showUserIsRegistered &&
                    <small className="heroSignUp--text-danger">A user with that email address is already registered.</small>
                }
                <input type="password" placeholder="Create password:" className="form-control mt-3 signUp--password"/>
                {
                  this.state.showPasswordRequirements &&
                    <small className="heroSignUp--text-danger">Password must be at least 6 characters long.</small>
                }
                <Button className="btn-block btn-danger btn-lg mt-3" onClick={() => this.handleSignUp()}>Sign Up</Button>
                <h6 className="text-center text-white mt-4 learnMore" onClick={() => this.goToValueProps()}>Learn more</h6>
              </FormGroup>
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid={true} className="my-5 valueProp" id="valueProps">
        <Row className="setWorkingHours">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="col--center">
            <h1 className="text-center mt-5 mb-4">Stay mindful and focused.</h1>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="col--center">
            <ul className="valueProp--list">
              <li>Set up your working hours for each day of the week.</li>
            </ul>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="col--center">
            <Image fluid rounded height="600" width="1000" src={editSchedules} />{/* Make a .gif for creating the calendar */}
          </Col>
        </Row>
      </Container>
      <Container fluid={true} className="my-5">
        <Row className="blockDistractingSites">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="col--center">
            <h1 className="text-center mt-5 mb-4">Dodge time wasters.</h1>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="col--center">
            <Image fluid rounded height="600" width="1000" src={blockSites}/>{/* Make a .gif for creating the calendar */}
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="col--center">
            <ul className="valueProp--list">
              <li>Block access to distracting sites during working hours.</li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container fluid={true} className="my-5">
        <Row className="setReminders">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="col--center">
            <h1 className="text-center mt-5 mb-4">Crush your to-do list.</h1>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="col--center">
              <ul className="valueProp--list">
                <li>Use the browser extension to keep tasks top of mind.</li>
              </ul>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="col--center">
          <Image fluid rounded height="600" width="1000" src={crushTasks}/>{/* Make a .gif for creating the calendar */}
          </Col>
        </Row>
      </Container>
      <Container fluid={true}>
        <Row className="setReminders mb-5">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="col--center">
            <h1 className="text-center">Ready to be more productive?</h1>
          </Col>
          <Button className="btn btn-primary pl-5 pr-5 btn-lg mb-5 mt-3 mx-auto" onClick={() => this.focusSignUp()}>Sign up</Button>

        </Row>
      </Container>
      <Container fluid={true} className="footer py-5">
        <Row className="mb-5">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-align-center text-center">
            <a className="text-center text-white mx-2" href="/privacy-policy">Privacy Policy</a>
            <a className="text-center text-white mx-2" href="/terms-of-use">Terms of Use</a>
          </Col>
        </Row>
      </Container>
    </main>
  </div>
  )
}
}

export default withRouter(LandingPage)