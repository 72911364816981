import React, { Component } from 'react';

import { ColumnChart } from 'react-chartkick'
import 'chart.js'
import moment from 'moment'
import './BarChart.scss';
import Cookies from 'universal-cookie';


class BarChart extends React.Component {

	constructor(props) {
        super(props);
        this.state = { 
			dates: null,
			tasksData: null
         }
         this.componentWillMount = () => {
		  this.getDates()
		  this.getTaskData()
        }
    }

	getDates = () => {
		const datesData = []
		let i;
		for (i = 0; i < 5; i++) {
			var dt = new Date();
			dt.setDate(dt.getDate() - i);
			var prettyDate = (dt.getMonth()+1) + '/' + dt.getDate()
			datesData.push([
				prettyDate,
				0
			])
		}

		datesData.reverse()

		this.setState({
			dates: datesData
		})
	}

    getTaskData = () => {
		const cookies = new Cookies()

		// Function to get the user data in here
		const currentTime = moment().format('YYYY-MM-DD hh:mm:ss');
		let lookBackTime = moment().subtract(5, 'days')
		lookBackTime = lookBackTime.format("YYYYY-MM-DD hh:mm:ss");
		const userData = cookies.get('userData');
		const sessionData = cookies.get('sessionData');
        let formData = {
			email: userData.email,
			clientAuthToken: sessionData.authToken,
			currentTime: currentTime,
			lookBackTime: lookBackTime
		}
        fetch(`${process.env.REACT_APP_SITE_URL}/getTaskData`, {
          method: "POST",
          headers: {
              'Content-type': 'application/json'
          },
          body: JSON.stringify(formData)
        })
        .then(res => res.json())
        .then((res) => {
			let tasksCompleted
			let newDates = []
			this.state.dates.map((dateToCheck, taskIndex) => {
				tasksCompleted = 0
				res.map((task, dateIndex) => {
					let dtc = new Date()
					let jsDate = new Date(dateToCheck[0] + '/' + dtc.getFullYear())
					let dbDate = new Date(task.completedOn)
					if(jsDate.getDate() === dbDate.getDate()) {
						tasksCompleted = tasksCompleted + 1
						dateToCheck[1] = tasksCompleted
					}
					newDates.push(dateToCheck)
				})
				
			})
			this.setState({
				dates: newDates
			})
        })
    }

	render() {
		return (
			<ColumnChart data={this.state.dates} />
		);
    }
}

export default BarChart