import Cookies from 'universal-cookie';
import { withRouter } from 'react-router-dom';

class Auth {
    constructor() {
        this.authenticated = false
        this.theme = 'default'
    }

    login(email, password, cb) {
        let authData = {
            email: email,
            password: password
        };
        // Fetch login
        fetch(`${process.env.REACT_APP_SITE_URL}/signIn`, {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(authData)
        }).then(response => {
            return response.json()
        })
        .then((userIsAuthenticated) => {
            console.log(userIsAuthenticated ? userIsAuthenticated : 'no data')
            // Perform callback if the user is authenticated
            if(userIsAuthenticated !== false) {
                const userData = {
                    email: email,
                    theme: this.theme
                }
                const cookies = new Cookies()
                cookies.set('sessionData', userIsAuthenticated, { path: '/' })
                cookies.set('userData', userData, { path: '/' })
                this.authenticated = true
                cb()
            } else {
                // this.authenticated = false
                console.log('User not authenticated')
            }
        })
    }

    logout(cb) {
        this.authenticated = false
        cb()
    }

    async isAuthenticated() {
        // Get user's token from localStorage or cookie
        // Compare unencrypted auth token with session auth
        const cookies = new Cookies()
        const authData = cookies.get('sessionData')
        const userData = cookies.get('userData')
        this.theme = authData && authData.theme
        let authResponse
        // Get authToken from cookie or localStorage
        const isAuthenticatedResponse = fetch(`${process.env.REACT_APP_SITE_URL}/validateAuth`, {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(authData)
        }).then(response => {
            return response.json()
        })
        .then((sessionIsAuthenticated) => {
            authResponse = sessionIsAuthenticated
            if (authResponse === false) {
                cookies.remove('sessionData')
                cookies.remove('userData')
            }
            this.authenticated = authResponse
            return this.authenticated
        })
        return isAuthenticatedResponse || false;
    }
}

export default new Auth()