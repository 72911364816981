import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap'
import NavBar from '../NavBar'
import '../../App.scss'


class TermsOfUse extends Component {

    render() {
        return (
            <div className="SignUp">
                <NavBar className="navbar--default" />
                <main role="main" className="content">
                <div className="">
                <h1 className="text-center my-5">Terms of Use</h1>
                <Container fluid={true}>
                <Row>
                <Col xs={12} sm={8} md={5} lg={4} xl={9} className="col--center text-center mb-5">
                    <p><strong>1.</strong> Do not break the law while using this site.</p>
                    <p><strong>2.</strong> Do not engage in abusive behavior while using this site.</p>
                    <p><strong>3.</strong> Do not do things that will degrade the performance of this site.</p>
                    <p><strong>4.</strong> If you find a security vulnerability while using this site, please inform us immediately at support@dayhub.co.</p>
                </Col>
                </Row>
                </Container>
                <Container fluid={true} className="footer--absolute position-absolute py-5">
                    <Row className="mb-5">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-align-center text-center">
                        <a className="text-center text-white mx-2" href="/privacy-policy">Privacy Policy</a>
                        <a className="text-center text-white mx-2" href="/terms-of-use">Terms of Use</a>
                    </Col>
                    </Row>
                </Container>
            </div>
            </main >
          </div >
        )
    }
}

export default TermsOfUse;