import React from "react";
import './LineChart.scss';

import { LineChart as CKLineChart } from 'react-chartkick'
import 'chart.js'


class LineChart extends React.Component {
	componentDidMount = () => {
		this.showLineChart()
	}

	showLineChart = () => {
		// Morris.Line({
		// 	element: 'line-example',
		// 	data: [
		// 	  { y: '2006', a: 100, b: 90 },
		// 	  { y: '2007', a: 75,  b: 65 },
		// 	  { y: '2008', a: 50,  b: 40 },
		// 	  { y: '2009', a: 75,  b: 65 },
		// 	  { y: '2010', a: 50,  b: 40 },
		// 	  { y: '2011', a: 75,  b: 65 },
		// 	  { y: '2012', a: 100, b: 90 }
		// 	],
		// 	xkey: 'y',
		// 	ykeys: ['a', 'b'],
		// 	labels: ['Series A', 'Series B']
		// })
	}

	render() {
		return (
			<div className="line-example">
				<CKLineChart data={{"10/01": 8, "10/02": 8, "10/03": 6.5, "10/04": 7, "10/05": 8}} />
			</div>
		);
    }
}

export default LineChart