import { set } from "date-fns";

const now = new Date();

export const getTodayAtSpecificHour = (hour = 8, minutes = 0) =>
  set(now, { hours: hour, minutes: minutes, seconds: 0, milliseconds: 0 });

export const selectedInterval = [
  getTodayAtSpecificHour(8),
  getTodayAtSpecificHour(17)
];

export const timelineInterval = [
  getTodayAtSpecificHour(0),
  getTodayAtSpecificHour(24)
];

// export const disabledIntervals = [
//     { start: getTodayAtSpecificHour(16), end: getTodayAtSpecificHour(17) },
//     { start: getTodayAtSpecificHour(7), end: getTodayAtSpecificHour(12) },
//     { start: getTodayAtSpecificHour(20), end: getTodayAtSpecificHour(24) }
// ];

export const disabledIntervals = [];
