const mapThemeToClasses = (themePreference) => {
    if (themePreference === 'default') {
        document.body.classList.remove('body--dark')
        document.body.classList.remove('text-white')
        document.body.classList.remove('btns--dark')
        document.body.classList.remove('tabs--dark')
        document.body.classList.remove('cards--dark')
        document.body.classList.remove('modals--dark')
        document.body.classList.remove('forms--dark')
    }
    if (themePreference === 'dark') {
        document.body.classList.add('body--dark')
        document.body.classList.add('text-white')
        document.body.classList.add('btns--dark')
        document.body.classList.add('tabs--dark')
        document.body.classList.add('cards--dark')
        document.body.classList.add('modals--dark')
        document.body.classList.add('forms--dark')
    }
}

export default mapThemeToClasses