import React, { Component } from 'react';
import './App.scss';
import { Card, Button, Modal, Form, Row, Col, ListGroup, Alert } from 'react-bootstrap'
import { Settings, Clock, List, Save, PlusCircle, ThumbsDown, CheckCircle } from 'react-feather'
import { withRouter } from 'react-router-dom';
import auth from './auth'
import { NavBar, BarChart, TimeSlider } from './components'
import { getTodayAtSpecificHour } from './components/TimeSlider/datesSource'

import Cookies from 'universal-cookie'
import mapThemeToClasses from './utils/themes'
import moment from 'moment'

const cookies = new Cookies()
const userData = cookies.get('userData')
const sessionData = cookies.get('sessionData')
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      sessionAuthenticated: null,
      showWorkingHours: false,
      showManageSites: false,
      showSetReminders: false,
      showAlert: false,
      alertText: '',
      availableDays: [],
      badSites: [],
      reminders: [],
      workSchedules: [],
      barChartData: [],
      lineChartData: [],
      horizontalBarChartData: [],
      recentlyCompleted: [],
      themePreference: userData && userData.theme || 'default'
     }
     this.addBadSite = this.addBadSite.bind(this)
     this.addReminder = this.addReminder.bind(this)
     this.updateHours = this.updateHours.bind(this)


     this.componentWillMount = () => {
      mapThemeToClasses(userData && userData.theme)

      auth.isAuthenticated().then((authResponse) => {
        if(authResponse === true) {
          this.setState({ sessionAuthenticated: true })
        } else {
          this.setState({ sessionAuthenticated: false })
          // window.location.href = '/'
        }
      })

      // Retrieve user data from the backend and populate the state
      this.getUserData()

      // If we were sent from the 'Actions' tab of Chrome extension, find which action to perform 
      const queryString = new URLSearchParams(this.props.location.search)
      const action = queryString.get('action')
      if (action === 'editTasks') {
        this.setState({
          showSetReminders: true
        })
      }
      if (action === 'editSchedule') {
        this.setState({
          showWorkingHours: true
        })
      }
      if (action === 'editSites') {
        this.setState({
          showManageSites: true
        })
      }
    }
  }

  timeConvert (time) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }
  

  updateHours = (scheduleIndex, selectedInterval) => {
    let newSchedules = [...this.state.workSchedules]
    let startInterval = selectedInterval[0]
    let endInterval = selectedInterval[1]

    newSchedules[scheduleIndex].workHours[0].startHour = startInterval.getHours()
    newSchedules[scheduleIndex].workHours[0].startMinute = startInterval.getMinutes()
    newSchedules[scheduleIndex].workHours[0].endHour = endInterval.getHours()
    newSchedules[scheduleIndex].workHours[0].endMinute = endInterval.getMinutes()

    // Determine whether to prepend a 0 or not to minutes/hours
    let startHourText = startInterval.getHours().toString().length > 1 
      ? startInterval.getHours() 
      : '0' + startInterval.getHours()
      let startMinuteText = startInterval.getMinutes().toString().length > 1 
      ? startInterval.getMinutes() 
      : '0' + startInterval.getMinutes()

    let endHourText = endInterval.getHours().toString().length > 1 
      ? endInterval.getHours() 
      : '0' + endInterval.getHours()

    let endMinuteText = endInterval.getMinutes().toString().length > 1 
      ? endInterval.getMinutes() 
      : '0' + endInterval.getMinutes()

    // Convert 24 hour format to 12 hour format for start and end interval display text
    let startIntervalDisplayText = startHourText + ':' + startMinuteText
    startIntervalDisplayText = this.timeConvert(startIntervalDisplayText)

    let endIntervalDisplayText = endHourText + ':' + endMinuteText
    endIntervalDisplayText = this.timeConvert(endIntervalDisplayText)

    document.querySelectorAll(`.scheduleIndex--${scheduleIndex}`)[0].innerHTML = startIntervalDisplayText
    document.querySelectorAll(`.scheduleIndex--${scheduleIndex}`)[1].innerHTML = endIntervalDisplayText

    // Now that the object is updated send it to the backend.
    const userData = cookies.get('userData')
    const sessionData = cookies.get('sessionData')
    let formData = {
      email: userData.email,
      clientAuthToken: sessionData.authToken,
      workSchedules: newSchedules
    }

    fetch(`${process.env.REACT_APP_SITE_URL}/updateUserSchedules`, {
      method: "POST",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(res => res.json())
  }
  

  getUserData = () => {
    const leSessionData = cookies.get('sessionData')
    const leUserData = cookies.get('userData')

    let formData = {
      email: leUserData && leUserData.email,
      clientAuthToken: leSessionData && leSessionData.authToken
    }
    fetch(`${process.env.REACT_APP_SITE_URL}/getUserData`, {
      method: "POST",
      headers: {
          'Content-type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(res => res.json())
    .then((res) => {
      res.reminders.reverse()
      res.recentlyCompleted.reverse()      
      this.setState({ badSites: res.badSites })
      this.setState({ reminders: res.reminders })
      this.setState({ workSchedules: res.workSchedules })
      this.setState({ recentlyCompleted: res.recentlyCompleted })
      const sessionData = cookies.get('sessionData')


      if (!userData) {
        if(!sessionData) {
          // window.location.href = '/'
        } else {
          cookies.set('userData', {
            email: sessionData.email,
            theme: res.themePreference
          })
          this.setState({ themePreference: res.themePreference })
          window.location.reload(true)
        }
      } else {
        this.setState({ themePreference: userData && userData.theme || res.themePreference })
      }
    })
  } 

  showWorkingHours = () => {
    this.setState({ showWorkingHours: true })
  }

  hideWorkingHours = () => {
    this.setState({ showWorkingHours: false })
  }

  showManageSites = () => {
    this.setState({ showManageSites: true })
  }

  hideManageSites = (showAlertAfterClose) => {
    this.setState({ showManageSites: false })
    if (showAlertAfterClose === true) {
      this.showAlert('success', 'Success! Sites saved successfully.')
      this.setState({ showAlert: true })
    }
  }

  showSetReminders = () => {
    this.setState({ showSetReminders: true })
  }

  hideSetReminders = (showAlertAfterClose) => {
    this.setState({ showSetReminders: false })
    if (showAlertAfterClose === true) {
      this.showAlert('success', 'Success! Tasks saved successfully.')
      this.setState({ showAlert: true })
    }
  }

  hideAlert = () => {
    this.setState({ showAlert: false })
  }

  showAlert = (alertType, alertText) => {
    this.setState({ showAlert: true })
    this.setState({ alertType: alertType })
    this.setState({ alertText: alertText })
  }

  showHours = (defaultHour) => {
    let hours = []
    for (let i = 1; i <= 12; i++) {
      defaultHour == i 
      ? hours.push(<option value={i} selected>{i}</option>) 
      : hours.push(<option value={i}>{i}</option>)
    }
    return hours
  }

  showMinutes = (defaultMinute) => {
    let minutes = []
    for (let i = 0; i <= 59; i++) {
      if (i < 10){
        i = `0${i}`
      }
      defaultMinute == i 
        ? minutes.push(<option value={i} selected>{i}</option>) 
        : minutes.push(<option value={i}>{i}</option>)
    }
    return minutes
  }

  showPeriods = (defaultPeriod) => {
    let periods = []
    if (defaultPeriod === 'am') {
      periods.push(<React.Fragment>
        <option value="am" selected>a.m.</option>
        <option value="pm">p.m.</option>
      </React.Fragment>)
    } else {
      periods.push(<React.Fragment>
        <option value="am">a.m.</option>
        <option value="pm" selected>p.m.</option>
      </React.Fragment>)
    }
    return periods
  }

  showIntervalType = (defaultInterval) => {
    let intervals = []
    if (defaultInterval === 'Work') {
      intervals.push(<React.Fragment>
        <option value="Work" selected>Work</option>
        <option value="Break">Break</option>
        <option value="Sleep">Sleep</option>
        <option value="Exercise">Exercise</option>
        <option value="Study">Study</option>
      </React.Fragment>)
    } else {
      intervals.push(<React.Fragment>
        <option value="Work">Work</option>
        <option value="Break" selected>Break</option>
        <option value="Sleep">Sleep</option>
        <option value="Exercise">Exercise</option>
        <option value="Study">Study</option>
      </React.Fragment>)
    }
    return intervals
  }

  addWorkHoursInterval = (scheduleIndex) => {
    // Make existing schedule hours disabled
    let newSchedules = [...this.state.workSchedules]
    let scheduledHours = []
    // Get existing scheduled hours
    newSchedules[scheduleIndex].workHours.forEach((workHour, workHourIndex) => {
      scheduledHours.push([workHour.startHour, workHour.endHour])
    })
    newSchedules[scheduleIndex].workHours = newSchedules[scheduleIndex]
    .workHours
    .concat([{
      startHour: '17',
      startMinute: '01',
      startPeriod: 'pm',
      endHour: '22',
      endMinute: '59',
      endPeriod: 'pm',
      intervalType: 'Study',
      disabledIntervals: [scheduledHours[scheduledHours.length - 1][0], scheduledHours[scheduledHours.length - 1][1]]
    }])
    this.setState({ [newSchedules[scheduleIndex]]: newSchedules })
  }

  removeWorkHours = (scheduleIndex, workHoursIndex) => {
    let newSchedules = [...this.state.workSchedules]
    newSchedules[scheduleIndex].workHours.splice(workHoursIndex, 1)
    this.setState({ [newSchedules[scheduleIndex]]: newSchedules })
  }

  getAvailableDays = () => {
    let dayList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const existingDays = []
    this.state.workSchedules && this.state.workSchedules.map((workSchedule, workScheduleIndex) => {
      workSchedule.days.map((workScheduleDay, workScheduleDayIndex) => {
        existingDays.push(workScheduleDay)
      })
    })
    let diffDays = dayList.filter(dayName => !existingDays.includes(dayName))
    return diffDays
  }

  addWorkSchedule = () => {
    let newState = { ...this.state }
    let dayList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    let newExistingDays = []
    this.state.workSchedules.map((workSchedule) => {
      workSchedule.days.map((day) => {
        newExistingDays.push(day)
      })
    })

    let diffDays = dayList.filter(dayName => !newExistingDays.includes(dayName))
    // Basically, anytime thee user makes changes to his schedule, we will delete the existing user schedule data and insert what we have.
      if (diffDays.length !== 0){
      // accumulate all days that exist in all arrays
      // diff the days that exist in all arrays vs weekdays. Put the difference in the days: field. If the length of it is 0 then prevent the button from being clicked
      newState.workSchedules.push({
        scheduleId: null,
        scheduleName: 'Workweek',
        days: diffDays,
        workHours: [{
          startHour: '8',
          startMinute: '00',
          startPeriod: 'am',
          endHour: '17',
          endMinute: '00',
          endPeriod: 'pm',
          intervalType: 'Work'
        }],
      })
      this.setState({ [newState.workSchedules]: newState.workSchedules })
    } else {
      this.showAllDaysAllocatedWarning()
    }
  }

  showAllDaysAllocatedWarning = () => {
    const addScheduleButtonIndex = this.state.workSchedules.length - 1
    const lastAddScheduleButton = document.querySelectorAll('.workSchedule__addSchedule--button')[addScheduleButtonIndex]
    const warningMessage = '<p className="workSchedule__addSchedule--warning text-danger">All days have already been allocated.</p>'
    // Check to see if element exists. if not, add it.
    if (document.querySelectorAll('.workSchedule__addSchedule--warning').length === 0) {
      lastAddScheduleButton.insertAdjacentHTML('afterend', warningMessage)
    }
  }

  updateScheduleDays = (day, scheduleIndex) => {
    // 1. Get available days 
    // 2. If the day is not available, then make it available (add it to the list of available days)
    // 3. If the day is available, then make it unavailable (remove it from the )
    // (All we are doing is updating the availability of the day to be the opposite of its current state)
    let workDays = this.state.workSchedules[scheduleIndex].days
    const dayIndex = workDays.indexOf(day)
    let scheduleWasRemoved
    if (this.state.workSchedules[scheduleIndex].days.includes(day)) {
      workDays.splice(dayIndex, 1)
      // If all days are removed then remove the work schedule
      if (workDays.length === 0 && this.state.workSchedules.length !== 1) {
        let newState = { ...this.state }
        newState.workSchedules.splice(scheduleIndex, 1)
        this.setState({ [newState.workSchedules]: newState.workSchedules })
        scheduleWasRemoved = true
      }
    } else {
      workDays.push(day)
    }
    if (!scheduleWasRemoved) {
      const newSchedules = [...this.state.workSchedules]
      newSchedules[scheduleIndex].days = workDays
      this.setState({ [newSchedules[scheduleIndex]]: newSchedules }, () => {})
    }
  }

  addBadSite(e){
    if(e.keyCode == 13){
      let newBadSites = [...this.state.badSites]
      const badSite = {
        siteAddress: e.target.value,
        siteDisplayName: e.target.value
      }
      newBadSites.push(badSite)
      this.setState({ badSites: newBadSites })
      document.querySelector('.addSiteInput--good').value = ''
      const userData = cookies.get('userData')
      const sessionData = cookies.get('sessionData')

      let formData = {
        siteToBlock: badSite.siteDisplayName,
        email: userData.email,
        clientAuthToken: sessionData.authToken
      }

      fetch(`${process.env.REACT_APP_SITE_URL}/addSite`, {
        method: "POST",
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      .then(res => res.json())
      .then(res => console.log(res))
    }
  }

  removeBadSite = (badSiteIndex) => {
    const userData = cookies.get('userData');
    const sessionData = cookies.get('sessionData');
    let newBadSites = [...this.state.badSites]
    let formData = {
      siteAddress: newBadSites[badSiteIndex].siteAddress,
      siteDisplayName: newBadSites[badSiteIndex].siteDisplayName,
      email: userData.email,
      clientAuthToken: sessionData.authToken
    }
    fetch(`${process.env.REACT_APP_SITE_URL}/removeSite`, {
      method: "POST",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(res => res.json())
    .then(() => newBadSites.splice(badSiteIndex, 1))
    .then(() => this.setState({ badSites: newBadSites }))
  }

  removeReminder = (reminderIndex) => {
    let newReminders = [...this.state.reminders]
    const userData = cookies.get('userData')
    const sessionData = cookies.get('sessionData')
    let formData = {
      id: newReminders[reminderIndex].id,
      email: userData.email,
      clientAuthToken: sessionData.authToken
    }
    fetch(`${process.env.REACT_APP_SITE_URL}/removeReminder`, {
      method: "POST",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(res => res.json())
    .then(() => newReminders.splice(reminderIndex, 1))
    .then(() => this.setState({ reminders: newReminders }))
  }

  completeReminder = (reminderIndex) => {
    const currentTime = moment().format('YYYY-MM-DD hh:mm:ss');
    let newReminders = [...this.state.reminders]
    const userData = cookies.get('userData')
    const sessionData = cookies.get('sessionData')
    let formData = {
      id: newReminders[reminderIndex].id,
      completedOn: currentTime,
      email: userData.email,
      clientAuthToken: sessionData.authToken
    }

    fetch(`${process.env.REACT_APP_SITE_URL}/completeReminder`, {
      method: "POST",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(res => res.json())
    .then(() => newReminders.splice(reminderIndex, 1))
    .then(() => this.setState({ reminders: newReminders }))
  }

  addReminder = (e) => {
    if(e.keyCode == 13) {
      let newReminders = this.state.reminders
      const reminderText = e.target.value
      const userData = cookies.get('userData')
      const sessionData = cookies.get('sessionData')
      let formData = {
        clientAuthToken: sessionData.authToken,
        email: userData.email,
        reminderText: reminderText
      }
      fetch(`${process.env.REACT_APP_SITE_URL}/addReminder`, {
        method: "POST",
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      .then(res => res.json())
      .then(res => newReminders.push({ id: res.insertId, reminderText: reminderText}))
      .then(res => this.setState({ reminders: newReminders }))
      .then(() => document.querySelector('.addReminder--input').value = '')
    }
  }

  saveWorkingHours = (e) => {
    const userData = cookies.get('userData')
    const sessionData = cookies.get('sessionData')
    let formData = {
      email: userData.email,
      clientAuthToken: sessionData.authToken,
      workSchedules: this.state.workSchedules
    }
    fetch(`${process.env.REACT_APP_SITE_URL}/updateUserSchedules`, {
      method: "POST",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(res => res.json())
    .then(this.hideWorkingHours())

    this.showAlert('success', 'Success! Working hours saved successfully.')
    this.setState({ showAlert: true })
  }

  getDaySelectorClassName = (workSchedules, day) => {
    let className = 'dayPicker__day '
    let availableDays = this.getAvailableDays()
    if (workSchedules.days.includes(day)) {
      className += 'dayPicker__day--active'
    } else if(!availableDays.includes(day)) {
      className += 'dayPicker__day--disabled'
    } else {
      className += ''
    }
    return className
  }

  handleDayPickerOnClick = (workSchedules, day) => {
    let availableDays = this.getAvailableDays()
    if (workSchedules.days.includes(day)) {
      return true
    } else if(!availableDays.includes(day)) {
      return false
    } else {
      return true
    }
  }

  getNavBar = () => {
    return (<NavBar sessionAuthenticated className={userData && userData.theme === 'dark' ? 'navbar--dark' : 'navbar--default'}/>)
}


  render (){
    var updateHours = this.updateHours;

    return (
    <div className="App">
    { this.state.sessionAuthenticated ?
    <React.Fragment>
    <Modal show={this.state.showWorkingHours} onHide={this.hideWorkingHours} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <Clock size={24} className="icon--cardHeader"/> Set Working Hours
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {
            this.state.workSchedules.map((workSchedules, scheduleIndex) => {
              return (
                <div className="workSchedule--container" key={scheduleIndex}>
                {/* <h3 className="text-bold">{workSchedules.scheduleName}</h3> */}
                <div className="dayPicker__container">
                  <div className={this.getDaySelectorClassName(workSchedules, 'Sunday')} onClick={() => this.handleDayPickerOnClick(workSchedules, 'Sunday') === true && this.updateScheduleDays('Sunday', scheduleIndex)}>
                    <span className="dayPicker__day--text">Su</span>
                  </div>
                  <div className={this.getDaySelectorClassName(workSchedules, 'Monday')} onClick={() => this.handleDayPickerOnClick(workSchedules, 'Monday') === true && this.updateScheduleDays('Monday', scheduleIndex)}>
                    <span className="dayPicker__day--text">Mo</span>
                  </div>
                  <div className={this.getDaySelectorClassName(workSchedules, 'Tuesday')} onClick={() => this.handleDayPickerOnClick(workSchedules, 'Tuesday') === true && this.updateScheduleDays('Tuesday', scheduleIndex)}>
                    <span className="dayPicker__day--text">Tu</span>
                  </div>
                  <div className={this.getDaySelectorClassName(workSchedules, 'Wednesday')} onClick={() => this.handleDayPickerOnClick(workSchedules, 'Wednesday') === true && this.updateScheduleDays('Wednesday', scheduleIndex)}>
                    <span className="dayPicker__day--text">We</span>
                  </div>
                  <div className={this.getDaySelectorClassName(workSchedules, 'Thursday')} onClick={() => this.handleDayPickerOnClick(workSchedules, 'Thursday') === true && this.updateScheduleDays('Thursday', scheduleIndex)}>
                    <span className="dayPicker__day--text">Th</span>
                  </div>
                  <div className={this.getDaySelectorClassName(workSchedules, 'Friday')} onClick={() => this.handleDayPickerOnClick(workSchedules, 'Friday') === true && this.updateScheduleDays('Friday', scheduleIndex)}>
                    <span className="dayPicker__day--text">Fr</span>
                  </div>
                  <div className={this.getDaySelectorClassName(workSchedules, 'Saturday')} onClick={() => this.handleDayPickerOnClick(workSchedules, 'Saturday') === true && this.updateScheduleDays('Saturday', scheduleIndex)}>
                    <span className="dayPicker__day--text">Sa</span>
                  </div>
                </div>
                <React.Fragment>
                  {
                    this.state.workSchedules[scheduleIndex].workHours.map((workHours, workHoursIndex) => {
                      return (
                        <Form.Group inline key={workHoursIndex}>
                          <div className="timeRangeSelector--header">
                            {/* <h5 className="mx-1">Work</h5> */}
                          </div>
                          <div className="row">
                            <div className="col">
                                <TimeSlider
                                startHour={getTodayAtSpecificHour(workHours.startHour, workHours.startMinute)} 
                                endHour={getTodayAtSpecificHour(workHours.endHour, workHours.endMinute)}
                                updateHours={updateHours.bind(this, scheduleIndex)}
                                scheduleIndex={scheduleIndex}/>
                            </div>
                          </div>
                        </Form.Group>
                      )
                    })
                  }
                  <hr />
                  {
                    scheduleIndex === (this.state.workSchedules.length - 1) &&
                    <Button disabled={this.getAvailableDays().length === 0 ? true : false}
                      className={`float-left btn-success mx-2 workSchedule__addSchedule--button ${this.getAvailableDays().length === 0 && 'disabled'}`} 
                      onClick={() => this.addWorkSchedule(scheduleIndex)}>
                        <PlusCircle className="icon--button" size={18}/>
                        &nbsp;Add Schedule
                    </Button>
                  }
                </React.Fragment>
              </div>
            )
            })
          }
        <br /><br />
        <Button 
          className="float-right js-saveWorkingHoursBtn"
          onClick={ () => this.saveWorkingHours() }>
          <Save className="icon--button" size={18}/> Save Working Hours
        </Button>
      </Form>
      </Modal.Body>
    </Modal>
    <Modal show={this.state.showManageSites} onHide={() => this.hideManageSites(false)} size={"md"}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Settings size={24} className="icon--cardHeader"/> Edit Sites
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <div className="addSiteContainer addSiteContainer--good">
            <Form.Group>
              <Form.Label className="mb-1 addSite--label"><ThumbsDown className="icon--button mx-1" size={18}/> Sites that waste time</Form.Label>
              <Form.Control className="addSiteInput--good" onKeyDown={this.addBadSite} type="text" size="md" placeholder="facebook.com"></Form.Control>
              <small>Type a website address and press 'Enter'.</small>
            </Form.Group>
            <ListGroup>
              {
                this.state.badSites && this.state.badSites.map((badSite, badSiteIndex) => {
                  return (
                    <ListGroup.Item key={badSiteIndex}>
                      {badSite.siteDisplayName}
                      <small className="float-right" onClick={() => { this.removeBadSite(badSiteIndex) }}>Remove</small>
                    </ListGroup.Item>
                  )
                })
              }
            </ListGroup>
          </div>
          <Button className="float-right signInSubmit" type="button" onClick={() => this.hideManageSites(true)}>
            <Save className="icon--button" size={18}/> Save Site Settings
          </Button>
      </Modal.Body>
    </Modal>
    <Modal show={this.state.showSetReminders} onHide={() => this.hideSetReminders(false)} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          <List size={24} className="icon--cardHeader"/> Edit Task Board
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <div className="addReminder--container">
            <Form.Group>
              <Form.Label className="mb-1 addReminder--label"><CheckCircle className="icon--button mx-1" size={18}/> Set a task</Form.Label>
              <Form.Control className="addReminder--input" onKeyDown={this.addReminder} type="text" size="md" placeholder="Task to complete"></Form.Control>
              <small>Press 'Enter' to add a task.</small>
            </Form.Group>
            <ListGroup>
              {
                this.state.reminders.map((reminder, reminderIndex) => {
                  return (
                    <ListGroup.Item key={reminderIndex}>
                      {reminder.reminderText}
                      <div className="reminderControls">
                        <small className="mx-1" onClick={() => { this.completeReminder(reminderIndex) }}>Complete</small>
                        |
                        <small className="mx-1 text-danger" onClick={() => { this.removeReminder(reminderIndex) }}>Delete</small>
                      </div>
                    </ListGroup.Item>
                  )
                })
              }
            </ListGroup>
          </div>
          <Button className="float-right signInSubmit" type="button" onClick={() => this.hideSetReminders(true)}>
            <Save className="icon--button" size={18} /> Save Tasks
          </Button>
      </Modal.Body>
    </Modal>
    {this.getNavBar()}
    <div className="secondaryNav">
      <Button className="ml-1 mr-1" onClick={this.showWorkingHours}><Clock className="icon--button mr-1" size={18} /> Set Working Hours</Button>
      <Button className="ml-1 mr-1" onClick={this.showManageSites}><Settings className="icon--button mr-2" size={18} />Edit Sites</Button>
      <Button className="ml-1 mr-1" onClick={this.showSetReminders}><List className="icon--button mr-2" size={18} />Edit Task Board</Button>
    </div>
    <main role="main" className="container">
      <div className="starter-template mt-4">
        <div className="notificationContainer">
          { this.state.showAlert &&
            <Alert variant={this.state.alertType} className="mb-4" onClose={this.hideAlert} dismissible>
              <p className="mx-0 px-0 mb-0">{this.state.alertText}</p>
            </Alert>
          }
        </div>
        <Row>
        <Col xs={12} md={6}>
          <Card className="text-center pt-3 my-5">
            <h3>Tasks per Day</h3>
            <BarChart 
              barChartData={this.state.barChartData}/>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card className="text-center pt-3 my-5">
            <h3>Recently Completed</h3>
            <ListGroup className="p-2 recentlyCompleted">
              {
                this.state.recentlyCompleted.length > 0 ? this.state.recentlyCompleted.map((task, taskIndex) => {
                  return (
                    <ListGroup.Item key={taskIndex}>{task.reminderText}</ListGroup.Item>
                  )
                })
                : <React.Fragment>
                  <div className="text-center">
                    <h3>You haven't completed any tasks yet.</h3>
                    <p>Try <a href="#" className="text-underline text-primary" onClick={() => this.showSetReminders()}>adding a task</a> and marking it 'Complete' when you're done.</p>
                  </div>
                  </React.Fragment>
              }
            </ListGroup>
          </Card>
        </Col>
      </Row>
      </div>
    </main>
    </React.Fragment>
    : <h1>Loading...{/* Implement a nice loading screen here */}</h1>}
  </div>
  )
}
}

export default withRouter(App)