import React, { Component } from 'react';
import { format } from 'date-fns'
import TimeRange from "react-timeline-range-slider";

import {
    disabledIntervals,
    timelineInterval,
    getTodayAtSpecificHour
} from './datesSource.js'
import './TimeSlider.scss';


class TimeSlider extends React.Component {

    state = {
        error: false,
        selectedInterval: [this.props.startHour, this.props.endHour]
    };
    errorHandler = ({ error }) => this.setState({ error });
    onChangeCallback = (selectedInterval, scheduleIndex) => this.setState({ selectedInterval });

	render() {
        const { selectedInterval, error } = this.state;
        const { startHour, endHour, updateHours, scheduleIndex } = this.props;
		return (
            <React.Fragment>
                <div className="info">
                    <span className="strong">Interval: </span>
                    {selectedInterval.map((d, i) => (
                        <span className={`timeRangeInterval scheduleIndex--${scheduleIndex}`} id={`scheduleIndex--${scheduleIndex}`} key={i}>{format(d, "hh:mm a")}</span>
                    ))}
                </div>
                <TimeRange
                    error={error}
                    ticksNumber={12}
                    selectedInterval={[startHour, endHour]}
                    timelineInterval={timelineInterval}
                    onUpdateCallback={this.errorHandler}
                    onChangeCallback={updateHours}
                    disabledIntervals={disabledIntervals}
                />
            </React.Fragment>

		);
    }
}

export default TimeSlider