import React, { Component } from 'react';

import { BarChart } from 'react-chartkick'
import 'chart.js'

import './HorizontalChart.scss';


class HorizontalChart extends React.Component {
	render() {
		return (
			<BarChart data={[["facebook.com", 9], ["twitter.com", 7], ["youtube.com", 5], ["news.google.com", 3], ["cnn.com", 2], ["bbc.co.uk", 1]]} />
		);
    }
}

export default HorizontalChart