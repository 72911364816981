import React, { Component } from 'react';
import Cookies from 'universal-cookie'
import { FormGroup, Row, Col, Container, Button } from 'react-bootstrap'
import NavBar from '../NavBar'
import '../../App.scss'


const cookies = new Cookies()
const userData = cookies.get('userData')
const sessionData = cookies.get('sessionData')

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEqualityRequirement: false,
            showLengthRequirement: false,
            showGoToDashboard: false,
            showGoToHomePage: false,
            disablePasswordResetForm: false,
        }
        const queryString = new URLSearchParams(this.props.location.search)
        const token = queryString.get('token')
        const email = queryString.get('email')
        if (!token || !email) {
            window.location.href = '/'
        }
        this.updatePassword = this.updatePassword.bind(this)
    }

    backToDayHub = () => {
        window.location.href = '/app'
    }

    backToLandingPage = () => {
        window.location.href = '/'
    }

    updatePassword = () => {
        const password = document.querySelector('.password').value
        const confirmPassword = document.querySelector('.confirmPassword').value
        const queryString = new URLSearchParams(this.props.location.search)
        const token = queryString.get('token')
        const email = queryString.get('email')

        if (password.length < 6) {
            this.setState(() => {
                return {
                    showLengthRequirement: true
                }
            })
          } else if (password !== confirmPassword) {
              this.setState(() => {
                  return {
                      showEqualityRequirement: true
                  }
              })
          } else {
              if (email && token && password) {
                const formData = {
                    password: password,
                    email: email,
                    token: token
                }
                this.setState(() => {
                    return {
                        showEqualityRequirement: false,
                        showLengthRequirement: false
                    }
                })
                fetch(`${process.env.REACT_APP_SITE_URL}/updatePassword`, {
                    method: "POST",
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                })
                .then((res) => {
                    this.setState(() => {
                        return {
                            disablePasswordResetForm: true
                        }
                    })
                })

              }
          }
    }

    render (){
        return (
            <div className="SignUp">
            <NavBar className="navbar--default" />
            <main role="main" className="content">
              <div className="heroImage">
                <h1 className="text-center text-white">Reset your password.</h1>
                <h4 className="text-center text-white">Enter and confirm your new password below.</h4>
                <Container fluid={true}>
                  <Row>
                    <Col xs={12} sm={8} md={5} lg={4} xl={3} className="col--center">
                        {
                            this.state.disablePasswordResetForm ?
                            <React.Fragment>
                              <p className="text-white text-center">Success! Your password has been reset.</p>
                              {
                                  sessionData.authToken
                                  ? <Button className="btn-block btn-danger btn-lg mt-3" onClick={() => this.backToDayHub()}>Back to DayHub</Button>
                                  : <Button className="btn-block btn-danger btn-lg mt-3" onClick={() => this.backToLandingPage()}>Log In</Button>

                              }
                            </React.Fragment>
                            : <FormGroup>
                            <input type="password" placeholder="New password:" className="form-control mt-3 password"/>
                            <input type="password" placeholder="Repeat new password:" className="form-control mt-3 confirmPassword"/>
                            {
                              this.state.showEqualityRequirement ?
                                <small className="heroSignUp--text-danger">Password and confirm password must match.</small>
                                : null
                            }
                            {
                              this.state.showLengthRequirement ? 
                                <small className="heroSignUp--text-danger">Password be longer than 6 characters.</small>
                                : null
                            }
                            <Button className="btn-block btn-danger btn-lg mt-3" onClick={() => this.updatePassword()}>Confirm and Update Password</Button>
                          </FormGroup>
                          
                        }
                    </Col>
                  </Row>
                </Container>
              </div>
            </main>
          </div>
        )
    }
}

export default ResetPassword;